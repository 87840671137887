<template>
  <div>
    <patient-edit />
    <form-time />
  </div>
</template>

<script>
import patientEdit from '../Patient-edit.vue'
import formTime from '../../form/other/Form-other-time.vue'

export default ({
  components: {
    patientEdit,
    formTime,
  },

})
</script>
