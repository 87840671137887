<template>
  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="timeRules">
        <b-form>
          <b-row>

            <b-col
              cols="2"
            >
              <b-form-group
                label="Horário"
                label-for="time"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="horário"
                  rules="required"
                >

                  <b-form-input
                    id="name"
                    v-model="time"
                    name="register-number"
                    :state="errors.length > 0 ? false:null"
                    type="time"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="button"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <b-button
          type="button"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>
    </b-card>
    <form-other-abstract />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formOtherAbstract from './Form-other-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,

    ValidationProvider,
    ValidationObserver,

    formOtherAbstract,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      time: '',
      items: [
        {
          text: 'Título',
          active: true,
        },
        {
          text: 'Horário',
          active: false,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  mounted() {
    this.time = this.$store.getters['formOther/time']
    this.link = this.$store.getters['formOther/link']
  },

  methods: {
    next() {
      this.$refs.timeRules.validate().then(success => {
        if (success) {
          this.$store.commit('formOther/UPDATE_TIME', this.time)
          this.$store.commit('formOther/UPDATE_SHOW_TIME', true)
          this.$router.push(`${this.link}-frequency`)
        }
      })
    },
    previous() {
      this.$router.push(`${this.link}-title`)
    },
    cancel() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', true)
      this.$router.push('patient-edit')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
